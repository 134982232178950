<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('aqversionenginepowerratio.title')">
					<v-layout wrap>
						<v-flex xs12 md6>
							<pui-text-field
								:label="$t('aqversionenginepowerratio.name')"
								v-model="model.name"
								required
								toplabel
								maxlength="150"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md3>
							<pui-text-field
								:label="$t('aqversionenginepowerratio.acronym')"
								v-model="model.acronym"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md3>
							<pui-text-field
								:label="$t('aqversionenginepowerratio.versionnumber')"
								v-model="model.versionnumber"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md3>
							<pui-select
								v-if="isSuperAdmin"
								attach="pmorganizationid"
								:label="this.$t('aqversionenginepowerratio.organization')"
								:placeholder="this.$t('aqversionenginepowerratio.phorganization')"
								toplabel
								clearable
								:disabled="formDisabled"
								required
								v-model="model"
								modelName="vluppmorganization"
								:modelFormMapping="{ pmorganizationid: 'pmorganizationid' }"
								:itemsToSelect="organizationItemsToSelect"
								itemValue="pmorganizationid"
								itemText="name"
							></pui-select>
							<pui-text-field
								v-else
								:label="$t('pmdatasourcetype.organizationname')"
								toplabel
								disabled
								required
								v-model="organizationName"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md12>
							<pui-text-area
								v-model="model.description"
								:label="$t('aqversionenginepowerratio.description')"
								maxlength="250"
								toplabel
							></pui-text-area>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-checkbox
								:label="$t('aqversionenginepowerratio.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import { isSuperAdmin } from '@/api/common';

export default {
	name: 'aqversionenginepowerratioform',
	mixins: [PuiFormMethodsMixin],

	data() {
		return {
			modelName: 'aqversionenginepowerratio',
			model: 'aqversionenginepowerratio',
			isSuperAdmin: false
		};
	},
	beforeCreate() {},
	created() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
		if (this.model) {
			this.modelLoaded = true;
			if (!this.isSuperAdmin) {
				this.model.pmorganizationid = this.userProperties.organizationid;
			}
		}
	},
	updated() {
		if (this.model) {
			if (!this.isSuperAdmin && !this.model.pmorganizationid) {
				this.model.pmorganizationid = this.userProperties.organizationid;
			}
		}
	},
	computed: {
		organizationItemsToSelect() {
			return [{ pmorganizationid: this.model.pmorganizationid }];
		},
		organizationName() {
			return this.userProperties.organizationname;
		}
	},
	methods: {}
};
</script>
